"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function MoneyReceiveIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13.05 16.4006H11.17C9.84001 16.4006 8.75 15.2806 8.75 13.9006C8.75 13.4906 9.09 13.1506 9.5 13.1506C9.91 13.1506 10.25 13.4906 10.25 13.9006C10.25 14.4506 10.66 14.9006 11.17 14.9006H13.05C13.44 14.9006 13.75 14.5506 13.75 14.1206C13.75 13.5806 13.6 13.5006 13.26 13.3806L10.25 12.3306C9.61 12.1106 8.75 11.6406 8.75 10.1706C8.75 8.92062 9.74001 7.89062 10.95 7.89062H12.83C14.16 7.89062 15.25 9.01063 15.25 10.3906C15.25 10.8006 14.91 11.1406 14.5 11.1406C14.09 11.1406 13.75 10.8006 13.75 10.3906C13.75 9.84063 13.34 9.39062 12.83 9.39062H10.95C10.56 9.39062 10.25 9.74062 10.25 10.1706C10.25 10.7106 10.4 10.7906 10.74 10.9106L13.75 11.9606C14.39 12.1806 15.25 12.6506 15.25 14.1206C15.25 15.3806 14.26 16.4006 13.05 16.4006Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 17.4023C11.59 17.4023 11.25 17.0623 11.25 16.6523V7.65234C11.25 7.24234 11.59 6.90234 12 6.90234C12.41 6.90234 12.75 7.24234 12.75 7.65234V16.6523C12.75 17.0623 12.41 17.4023 12 17.4023Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 22.9023C6.07 22.9023 1.25 18.0823 1.25 12.1523C1.25 6.22234 6.07 1.40234 12 1.40234C12.41 1.40234 12.75 1.74234 12.75 2.15234C12.75 2.56234 12.41 2.90234 12 2.90234C6.9 2.90234 2.75 7.05234 2.75 12.1523C2.75 17.2523 6.9 21.4023 12 21.4023C17.1 21.4023 21.25 17.2523 21.25 12.1523C21.25 11.7423 21.59 11.4023 22 11.4023C22.41 11.4023 22.75 11.7423 22.75 12.1523C22.75 18.0823 17.93 22.9023 12 22.9023Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M21 7.90234H17C16.59 7.90234 16.25 7.56234 16.25 7.15234V3.15234C16.25 2.74234 16.59 2.40234 17 2.40234C17.41 2.40234 17.75 2.74234 17.75 3.15234V6.40234H21C21.41 6.40234 21.75 6.74234 21.75 7.15234C21.75 7.56234 21.41 7.90234 21 7.90234Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16.9995 7.90375C16.8095 7.90375 16.6195 7.83375 16.4695 7.68375C16.1795 7.39375 16.1795 6.91375 16.4695 6.62375L21.4695 1.62375C21.7595 1.33375 22.2395 1.33375 22.5295 1.62375C22.8195 1.91375 22.8195 2.39375 22.5295 2.68375L17.5295 7.68375C17.3795 7.83375 17.1895 7.90375 16.9995 7.90375Z"
  }));
}


exports.default = MoneyReceiveIcon;
