"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function ShiedSearchIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 21 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.2663 19.2232C9.32461 19.2232 8.42461 18.9482 7.74961 18.4398L4.16628 15.7648C3.21628 15.0565 2.47461 13.5815 2.47461 12.3982V6.19818C2.47461 4.91484 3.41628 3.54818 4.62461 3.09818L8.78294 1.53984C9.60794 1.23151 10.9079 1.23151 11.7329 1.53984L15.8996 3.09818C17.1079 3.54818 18.0496 4.91484 18.0496 6.19818V9.05651C18.0496 9.39818 17.7663 9.68151 17.4246 9.68151C17.0829 9.68151 16.7996 9.39818 16.7996 9.05651V6.19818C16.7996 5.43984 16.1746 4.53984 15.4579 4.26484L11.2996 2.70651C10.7496 2.49818 9.77461 2.49818 9.22461 2.70651L5.06628 4.27318C4.34961 4.53984 3.72461 5.43984 3.72461 6.20651V12.3982C3.72461 13.1898 4.28294 14.2982 4.90794 14.7648L8.49128 17.4398C8.94961 17.7815 9.59128 17.9732 10.2579 17.9732C10.5996 17.9732 10.8829 18.2565 10.8829 18.5982C10.8829 18.9398 10.6079 19.2232 10.2663 19.2232Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13.599 17.5573C11.4156 17.5573 9.64062 15.7823 9.64062 13.599C9.64062 11.4156 11.4156 9.64062 13.599 9.64062C15.7823 9.64062 17.5573 11.4156 17.5573 13.599C17.5573 15.7823 15.7823 17.5573 13.599 17.5573ZM13.599 10.899C12.1073 10.899 10.8906 12.1156 10.8906 13.6073C10.8906 15.099 12.1073 16.3156 13.599 16.3156C15.0906 16.3156 16.3073 15.099 16.3073 13.6073C16.3073 12.1156 15.0906 10.899 13.599 10.899Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.765 18.5977C17.7066 18.5977 17.6566 18.5893 17.5983 18.581C17.5483 18.5727 17.4983 18.556 17.4483 18.531C17.3983 18.5143 17.3483 18.4893 17.2983 18.456C17.2566 18.4227 17.215 18.3893 17.1733 18.356C17.0233 18.1977 16.9316 17.981 16.9316 17.7643C16.9316 17.656 16.9566 17.5477 16.9983 17.4477C17.04 17.3477 17.0983 17.256 17.1733 17.1727C17.4816 16.8643 18.0483 16.8643 18.3566 17.1727C18.4316 17.256 18.49 17.3477 18.5316 17.4477C18.5733 17.5477 18.5983 17.656 18.5983 17.7643C18.5983 17.981 18.5066 18.1977 18.3566 18.356C18.1983 18.506 17.9816 18.5977 17.765 18.5977Z"
  }));
}


exports.default = ShiedSearchIcon;
