"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _Tabs = require('@mui/material/Tabs'); var _Tabs2 = _interopRequireDefault(_Tabs);
var _material = require('@mui/material');
var _mui = require('tss-react/mui');
var _theme = require('../../theme');
const UnderlinedTabs = _mui.withStyles.call(void 0, _Tabs2.default, (theme) => _material.createStyles.call(void 0, {
  root: {
    flex: 1
  },
  flexContainer: {
    borderBottom: `1.5px solid ${_theme.colors[theme.palette.mode].border.border1}`
  }
}));



exports.Tabs = _Tabs2.default; exports.UnderlinedTabs = UnderlinedTabs;
