"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _commontypes = require('common-types');
var _reactintl = require('react-intl');
const TRANSACTION_TYPE_TITLE_MAP = {
  [_commontypes.TransactionEventTypes.ERC20_APPROVAL]: _reactintl.defineMessage.call(void 0, {
    id: "ipFShi",
    defaultMessage: [{
      "type": 0,
      "value": "Approve Token"
    }]
  }),
  [_commontypes.TransactionEventTypes.ERC20_TRANSFER]: _reactintl.defineMessage.call(void 0, {
    id: "EqGNLY",
    defaultMessage: [{
      "type": 0,
      "value": "Transfer Token"
    }]
  }),
  [_commontypes.TransactionEventTypes.SWAP]: _reactintl.defineMessage.call(void 0, {
    id: "+vWRnj",
    defaultMessage: [{
      "type": 0,
      "value": "Swap"
    }]
  }),
  [_commontypes.TransactionEventTypes.NATIVE_TRANSFER]: _reactintl.defineMessage.call(void 0, {
    id: "BvJC2G",
    defaultMessage: [{
      "type": 0,
      "value": "Transfer Token"
    }]
  }),
  [_commontypes.TransactionEventTypes.DCA_WITHDRAW]: _reactintl.defineMessage.call(void 0, {
    id: "96hSIS",
    defaultMessage: [{
      "type": 0,
      "value": "Withdraw from Position"
    }]
  }),
  [_commontypes.TransactionEventTypes.DCA_MODIFIED]: _reactintl.defineMessage.call(void 0, {
    id: "blbha/",
    defaultMessage: [{
      "type": 0,
      "value": "Modified Position"
    }]
  }),
  [_commontypes.TransactionEventTypes.DCA_CREATED]: _reactintl.defineMessage.call(void 0, {
    id: "pQmQ4Q",
    defaultMessage: [{
      "type": 0,
      "value": "Created DCA Position"
    }]
  }),
  [_commontypes.TransactionEventTypes.DCA_PERMISSIONS_MODIFIED]: _reactintl.defineMessage.call(void 0, {
    id: "IW0RSP",
    defaultMessage: [{
      "type": 0,
      "value": "Position Permissions modified"
    }]
  }),
  [_commontypes.TransactionEventTypes.DCA_TRANSFER]: _reactintl.defineMessage.call(void 0, {
    id: "SQ2+Tc",
    defaultMessage: [{
      "type": 0,
      "value": "Position Transfered"
    }]
  }),
  [_commontypes.TransactionEventTypes.DCA_TERMINATED]: _reactintl.defineMessage.call(void 0, {
    id: "2Okh7Q",
    defaultMessage: [{
      "type": 0,
      "value": "Position Closed"
    }]
  }),
  [_commontypes.TransactionEventTypes.EARN_CREATED]: _reactintl.defineMessage.call(void 0, {
    id: "yDFg6c",
    defaultMessage: [{
      "type": 0,
      "value": "Earn Position Created"
    }]
  }),
  [_commontypes.TransactionEventTypes.EARN_INCREASE]: _reactintl.defineMessage.call(void 0, {
    id: "Kn2nyE",
    defaultMessage: [{
      "type": 0,
      "value": "Earn Position Increased"
    }]
  }),
  [_commontypes.TransactionEventTypes.EARN_WITHDRAW]: _reactintl.defineMessage.call(void 0, {
    id: "seDTd4",
    defaultMessage: [{
      "type": 0,
      "value": "Withdraw from Earn Position"
    }]
  })
};


exports.TRANSACTION_TYPE_TITLE_MAP = TRANSACTION_TYPE_TITLE_MAP;
