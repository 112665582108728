"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ = require('..');
var _reactintl = require('react-intl');
var _theme = require('../../theme');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _icons = require('../../icons');
var _3 = require('../..');
var _orderBy = require('lodash/orderBy'); var _orderBy2 = _interopRequireDefault(_orderBy);
var _omit = require('lodash/omit'); var _omit2 = _interopRequireDefault(_omit);
var _currency = require('../../common/utils/currency');
const StyledTokenTextContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  gap: 5,
  aligItems: "center"
})``;
const StyledForegroundPaper = _styledcomponents2.default.call(void 0, _.ForegroundPaper)`
  display: flex;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  box-shadow: none;
  flex-direction: column;
  ${(_ref) => {
  let {
    theme: {
      spacing,
      palette: {
        mode
      }
    }
  } = _ref;
  return `
    gap: ${spacing(1)};
    padding: ${spacing(3)};
    border-radius: ${spacing(2)};
    :hover {
      background-color: ${_theme.colors[mode].background.emphasis};
    }
  `;
}}
`;
const StyledCopyIcon = _styledcomponents2.default.call(void 0, _icons.ContentCopyIcon)`
  cursor: pointer;
`;
const validAddressRegex = RegExp(/^0x[A-Fa-f0-9]{40}$/);
const LoadingRow = (index) => /* @__PURE__ */ _react2.default.createElement(StyledForegroundPaper, {
  elevation: 0,
  key: index
}, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
  variant: "circular",
  width: 32,
  height: 32,
  animation: "wave"
}), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
  flexDirection: "column",
  flex: "1",
  alignItems: "flex-start"
}, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
  variant: "bodyRegular"
}, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
  variant: "text",
  animation: "wave",
  width: "15ch"
})), /* @__PURE__ */ _react2.default.createElement(_.Typography, {
  variant: "bodySmallRegular"
}, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
  variant: "text",
  animation: "wave",
  width: "5ch"
}))), /* @__PURE__ */ _react2.default.createElement(_.Typography, {
  variant: "bodySmallRegular"
}, /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
  variant: "text",
  animation: "wave",
  width: "5ch",
  height: "2ch"
})));
const EmptyRow = (_ref2) => {
  let {
    intl,
    message
  } = _ref2;
  return /* @__PURE__ */ _react2.default.createElement(StyledTokenTextContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyRegular",
    sx: {
      textAlign: "center"
    }
  }, message ? intl.formatMessage(message) : /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "JJVfTS",
    defaultMessage: [{
      "type": 0,
      "value": "We could not find any token with those search parameters. Paste the token address to add it to your list. Once added, we'll always track your balance and its price."
    }]
  })));
};
const ErrorRow = () => /* @__PURE__ */ _react2.default.createElement(StyledTokenTextContainer, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
  variant: "bodyRegular",
  sx: {
    textAlign: "center"
  }
}, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
  id: "3qQCTi",
  defaultMessage: [{
    "type": 0,
    "value": "We could not find a token at the provided address. Please double-check and try again."
  }]
})));
const Row = (index, tokenWithBalance, _ref3) => {
  let {
    isLoadingTokenBalances,
    onClick,
    isLoadingTokenPrices,
    themeMode,
    intl,
    snackbar
  } = _ref3;
  const {
    token,
    balance,
    allowsYield,
    isCustomToken
  } = tokenWithBalance;
  const balanceUsd = _optionalChain([balance, 'optionalAccess', _5 => _5.amountInUSD]);
  const balanceUnits = _optionalChain([balance, 'optionalAccess', _6 => _6.amountInUnits]);
  const onCopyAddress = (evt) => {
    evt.stopPropagation();
    if (token) {
      _3.copyTextToClipboard.call(void 0, token.address);
      snackbar.enqueueSnackbar(intl.formatMessage(_reactintl.defineMessage.call(void 0, {
        id: "8JRwsp",
        defaultMessage: [{
          "type": 0,
          "value": "Address copied to clipboard"
        }]
      })), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        TransitionComponent: _.Zoom
      });
    }
  };
  return /* @__PURE__ */ _react2.default.createElement(StyledForegroundPaper, {
    key: `${token.chainId}-${token.address}`,
    onClick: () => onClick(tokenWithBalance)
  }, /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flex: "1",
    alignItems: "center",
    gap: 3
  }, token.icon, /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    flex: "1",
    alignItems: "flex-start"
  }, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyBold",
    color: _theme.colors[themeMode].typography.typo2
  }, token.name), !isLoadingTokenBalances && !balanceUnits && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular",
    color: _theme.colors[themeMode].typography.typo3
  }, token.symbol), (isLoadingTokenBalances || balanceUnits) && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular",
    color: _theme.colors[themeMode].typography.typo3
  }, isLoadingTokenBalances && !balanceUnits ? /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
    variant: "text",
    animation: "wave",
    width: "5ch"
  }) : /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, _currency.formatCurrencyAmount.call(void 0, {
    amount: _optionalChain([balance, 'optionalAccess', _7 => _7.amount]),
    token,
    intl
  }), ` `, token.symbol))), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    gap: 2,
    alignItems: "center"
  }, allowsYield && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyExtraSmall",
    color: "success.dark"
  }, intl.formatMessage(_reactintl.defineMessage.call(void 0, {
    id: "ByRaJ4",
    defaultMessage: [{
      "type": 0,
      "value": "Supports yield"
    }]
  }))), (isLoadingTokenPrices || balanceUsd) && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyBold",
    color: _theme.baseColors.disabledText
  }, isLoadingTokenPrices && !balanceUsd ? /* @__PURE__ */ _react2.default.createElement(_.Skeleton, {
    variant: "text",
    animation: "wave",
    width: "5ch",
    height: "2ch"
  }) : /* @__PURE__ */ _react2.default.createElement(_.Chip, {
    color: "primary",
    size: "medium",
    label: `$${_currency.formatUsdAmount.call(void 0, {
      amount: balanceUsd,
      intl
    })}`
  }))), /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "h5",
    component: "span",
    sx: {
      display: "flex"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_.Tooltip, {
    title: token.address,
    arrow: true,
    placement: "top"
  }, /* @__PURE__ */ _react2.default.createElement(StyledCopyIcon, {
    fontSize: "inherit",
    onClick: onCopyAddress
  })))), isCustomToken && /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodyBold",
    color: _theme.baseColors.disabledText
  }, /* @__PURE__ */ _react2.default.createElement(_.Chip, {
    color: "warning",
    size: "medium",
    label: intl.formatMessage(_reactintl.defineMessage.call(void 0, {
      id: "H/e6If",
      defaultMessage: [{
        "type": 0,
        "value": "This is a custom token you are importing, trade at your own risk."
      }]
    }))
  })));
};
const TokenSearch = (_ref4) => {
  let {
    search,
    onChange
  } = _ref4;
  const intl = _reactintl.useIntl.call(void 0, );
  const onPasteAddress = async () => {
    const value = await navigator.clipboard.readText();
    onChange(value);
  };
  return /* @__PURE__ */ _react2.default.createElement(_.TextField, {
    placeholder: intl.formatMessage(_reactintl.defineMessage.call(void 0, {
      id: "Wad0yc",
      defaultMessage: [{
        "type": 0,
        "value": "Search your token by symbol, name or address"
      }]
    })),
    fullWidth: true,
    value: search,
    onChange: (evt) => onChange(evt.currentTarget.value),
    autoFocus: true,
    InputProps: {
      startAdornment: /* @__PURE__ */ _react2.default.createElement(_.InputAdornment, {
        position: "start"
      }, /* @__PURE__ */ _react2.default.createElement(_icons.SearchIcon, null)),
      endAdornment: /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
        gap: 1,
        alignItems: "center"
      }, /* @__PURE__ */ _react2.default.createElement(_.Tooltip, {
        title: /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
          id: "Kq/AiW",
          defaultMessage: [{
            "type": 0,
            "value": "Paste address from clipboard"
          }]
        }),
        arrow: true,
        placement: "top"
      }, /* @__PURE__ */ _react2.default.createElement(_icons.ContentPasteIcon, {
        onClick: () => void onPasteAddress()
      })), /* @__PURE__ */ _react2.default.createElement(_icons.SearchIcon, null))
    },
    onKeyDown: (e) => {
      if (e.key !== "Escape") {
        e.stopPropagation();
      }
    }
  });
};
const skeletonRows = Array.from(Array(10).keys());
const removeIcon = (token) => {
  const tokenWithoutReactComponents = _omit2.default.call(void 0, token, "icon");
  const underlying = _optionalChain([tokenWithoutReactComponents, 'access', _8 => _8.underlyingTokens, 'optionalAccess', _9 => _9.map, 'call', _10 => _10((underlyingToken) => removeIcon(underlyingToken))]);
  tokenWithoutReactComponents.underlyingTokens = underlying;
  return tokenWithoutReactComponents;
};
const removeIconFromTokenWithBalance = (tokenWithBalance) => {
  const tokenWithoutReactComponents = _omit2.default.call(void 0, tokenWithBalance.token, "icon");
  const underlying = _optionalChain([tokenWithoutReactComponents, 'access', _11 => _11.underlyingTokens, 'optionalAccess', _12 => _12.map, 'call', _13 => _13((underlyingToken) => removeIcon(underlyingToken))]);
  tokenWithoutReactComponents.underlyingTokens = underlying;
  return {
    ...tokenWithBalance,
    token: tokenWithoutReactComponents
  };
};
const TokenPicker = (_ref5) => {
  let {
    shouldShow,
    onChange,
    onClose,
    modalTitle,
    isLoadingCustomToken,
    tokens,
    onFetchCustomToken,
    allowedPairs,
    otherSelected,
    isLoadingTokens,
    filterByPair,
    isLoadingBalances,
    isLoadingPrices,
    protocolToken,
    wrappedProtocolToken,
    emptySearchMessage
  } = _ref5;
  const [search, setSearch] = _react.useState.call(void 0, "");
  const [isOnlyAllowedPairs, setIsOnlyAllowedPairs] = _react.useState.call(void 0, false);
  const intl = _reactintl.useIntl.call(void 0, );
  const {
    palette: {
      mode
    },
    spacing
  } = _3.useTheme.call(void 0, );
  const snackbar = _3.useSnackbar.call(void 0, );
  const handleOnClose = _react.useCallback.call(void 0, () => {
    setSearch("");
    onClose();
  }, [onClose]);
  const handleItemSelected = _react.useCallback.call(void 0, (token) => {
    onChange(removeIconFromTokenWithBalance(token));
    setSearch("");
    handleOnClose();
  }, [handleOnClose, onChange]);
  const itemData = _react.useMemo.call(void 0, () => ({
    onClick: handleItemSelected,
    isLoadingTokenBalances: isLoadingBalances,
    isLoadingTokenPrices: isLoadingPrices,
    themeMode: mode,
    intl,
    snackbar
  }), [isLoadingBalances, isLoadingPrices, handleItemSelected, mode, snackbar]);
  const filteredTokens = _react.useMemo.call(void 0, () => {
    const tokensMatchingSearch = _orderBy2.default.call(void 0, tokens.filter((_ref6) => {
      let {
        token
      } = _ref6;
      const tokenAddressForPair = (token.address === protocolToken.address ? wrappedProtocolToken.address : token.address).toLowerCase();
      const otherTokenAddressForPair = _optionalChain([otherSelected, 'optionalAccess', _14 => _14.address]) === protocolToken.address ? wrappedProtocolToken.address : _optionalChain([otherSelected, 'optionalAccess', _15 => _15.address, 'access', _16 => _16.toLowerCase, 'call', _17 => _17()]);
      const foundInPair = allowedPairs && tokenAddressForPair !== otherTokenAddressForPair && !!allowedPairs.find((_ref7) => {
        let {
          token0,
          token1
        } = _ref7;
        return (tokenAddressForPair === token0.toLowerCase() || tokenAddressForPair === token1.toLowerCase()) && (otherTokenAddressForPair === token0.toLowerCase() || otherTokenAddressForPair === token1.toLowerCase());
      });
      return (token.name.toLowerCase().includes(search.toLowerCase()) || token.symbol.toLowerCase().includes(search.toLowerCase()) || token.address.toLowerCase().includes(search.toLowerCase())) && (!filterByPair || !isOnlyAllowedPairs || foundInPair) && token.address !== _optionalChain([otherSelected, 'optionalAccess', _18 => _18.address]);
    }), [(_ref8) => {
      let {
        balance
      } = _ref8;
      return parseFloat(_optionalChain([balance, 'optionalAccess', _19 => _19.amountInUSD]) || "0");
    }, (_ref9) => {
      let {
        balance
      } = _ref9;
      return BigInt(_optionalChain([balance, 'optionalAccess', _20 => _20.amount]) || 0);
    }, "token.symbol"], ["desc", "desc", "desc"]);
    return tokensMatchingSearch;
  }, [tokens, search, allowedPairs, filterByPair, isOnlyAllowedPairs]);
  const onSearchChange = _react.useCallback.call(void 0, (newSearch) => {
    setSearch(newSearch);
    if (newSearch && validAddressRegex.test(newSearch) && onFetchCustomToken) {
      onFetchCustomToken(newSearch.toLowerCase());
    }
  }, [onFetchCustomToken]);
  const isLoading = isLoadingBalances || isLoadingCustomToken || isLoadingPrices || isLoadingTokens;
  let itemContentToRender;
  let dataToRender;
  if (isLoadingTokens || isLoadingCustomToken && filteredTokens.length === 0) {
    itemContentToRender = LoadingRow;
  } else {
    itemContentToRender = Row;
  }
  if (isLoadingTokens) {
    dataToRender = skeletonRows;
  } else if (isLoadingCustomToken && filteredTokens.length === 0) {
    dataToRender = Array.from(Array(1).keys());
  } else {
    dataToRender = filteredTokens;
  }
  return /* @__PURE__ */ _react2.default.createElement(_.Modal, {
    open: shouldShow,
    onClose: handleOnClose,
    closeOnBackdrop: true,
    maxWidth: "sm",
    keepMounted: true,
    fullHeight: true
  }, /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    alignSelf: "stretch",
    flex: 1
  }, /* @__PURE__ */ _react2.default.createElement(_.IconButton, {
    "aria-label": "close",
    size: "small",
    onClick: handleOnClose,
    style: {
      position: "absolute",
      top: spacing(6),
      right: spacing(8)
    }
  }, /* @__PURE__ */ _react2.default.createElement(_icons.CloseIcon, {
    fontSize: "inherit",
    color: "info"
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    container: true,
    rowSpacing: 5,
    direction: "column",
    style: {
      flexWrap: "nowrap"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 12,
    style: {
      flexBasis: "auto",
      alignSelf: "flex-start"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "h6",
    fontWeight: 700
  }, modalTitle)), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 12,
    style: {
      flexBasis: "auto"
    }
  }, /* @__PURE__ */ _react2.default.createElement(TokenSearch, {
    search,
    onChange: onSearchChange
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 12,
    style: {
      flexBasis: "auto"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_.DividerBorder2, null)), otherSelected && filterByPair && /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 12,
    sx: {
      justifyContent: "space-between",
      alignItems: "center",
      flex: "0 !important",
      alignSelf: "flex-start"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "gjyuVH",
    defaultMessage: [{
      "type": 0,
      "value": "Only tokens compatible with "
    }, {
      "type": 1,
      "value": "token"
    }],
    values: {
      token: _optionalChain([otherSelected, 'optionalAccess', _21 => _21.symbol])
    }
  }), /* @__PURE__ */ _react2.default.createElement(_.Switch, {
    checked: isOnlyAllowedPairs,
    onChange: () => setIsOnlyAllowedPairs(!isOnlyAllowedPairs),
    name: "isOnlyAllowedPairs",
    color: "primary"
  })), /* @__PURE__ */ _react2.default.createElement(_.Grid, {
    item: true,
    xs: 12,
    style: {
      flexGrow: 1
    }
  }, !isLoading && search && filteredTokens.length === 0 && validAddressRegex.test(search) && /* @__PURE__ */ _react2.default.createElement(ErrorRow, null), !isLoading && search && filteredTokens.length === 0 && !validAddressRegex.test(search) && /* @__PURE__ */ _react2.default.createElement(EmptyRow, {
    message: emptySearchMessage,
    intl
  }), /* @__PURE__ */ _react2.default.createElement(_.VirtualizedList, {
    context: itemData,
    data: dataToRender,
    itemContent: itemContentToRender
  })))));
};


exports.TokenPicker = TokenPicker;
