"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildInputBaseVariant = (mode) => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontWeight: "600",
        display: "flex",
        gap: _constants.SPACING.call(void 0, 2),
        "&:hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${_colors.colors[mode].accentPrimary} !important`
          }
        }
      },
      inputSizeSmall: {
        paddingTop: `${_constants.SPACING.call(void 0, 2.5)} !important`,
        paddingBottom: `${_constants.SPACING.call(void 0, 2.5)} !important`
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: _constants.SPACING.call(void 0, 2),
        backgroundColor: _colors.colors[mode].background.secondary,
        color: _colors.colors[mode].typography.typo1,
        "& .MuiSvgIcon-root": {
          transition: "color 200ms",
          color: _colors.colors[mode].typography.typo5
        },
        "&.Mui-focused": {
          borderColor: _colors.colors[mode].accentPrimary,
          backgroundColor: _colors.colors[mode].background.tertiary,
          ".MuiSvgIcon-root": {
            color: _colors.colors[mode].typography.typo3
          },
          borderWidth: "1.5px"
        },
        "&.Mui-disabled": {
          opacity: ".5",
          input: {
            WebkitTextFillColor: _colors.colors[mode].typography.typo1
          }
        },
        input: {
          "&::placeholder": {
            fontWeight: "600",
            color: _colors.colors[mode].typography.typo5,
            opacity: 1
          }
        }
      },
      notchedOutline: {
        transition: "border 200ms",
        borderColor: _colors.colors[mode].border.border1
      }
    }
  }
});


exports.buildInputBaseVariant = buildInputBaseVariant;
