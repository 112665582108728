"use strict";Object.defineProperty(exports, "__esModule", {value: true});var FeeType = /* @__PURE__ */ ((FeeType2) => {
  FeeType2["deposit"] = "deposit";
  FeeType2["withdraw"] = "withdraw";
  FeeType2["performance"] = "performance";
  FeeType2["save"] = "save";
  return FeeType2;
})(FeeType || {});
var StrategyYieldType = /* @__PURE__ */ ((StrategyYieldType2) => {
  StrategyYieldType2[StrategyYieldType2["LENDING"] = 0] = "LENDING";
  StrategyYieldType2[StrategyYieldType2["STAKING"] = 1] = "STAKING";
  return StrategyYieldType2;
})(StrategyYieldType || {});
var StrategyRiskLevel = /* @__PURE__ */ ((StrategyRiskLevel2) => {
  StrategyRiskLevel2[StrategyRiskLevel2["LOW"] = 0] = "LOW";
  StrategyRiskLevel2[StrategyRiskLevel2["MEDIUM"] = 1] = "MEDIUM";
  StrategyRiskLevel2[StrategyRiskLevel2["HIGH"] = 2] = "HIGH";
  return StrategyRiskLevel2;
})(StrategyRiskLevel || {});
var EarnPositionActionType = /* @__PURE__ */ ((EarnPositionActionType2) => {
  EarnPositionActionType2["CREATED"] = "created";
  EarnPositionActionType2["INCREASED"] = "increased";
  EarnPositionActionType2["WITHDREW"] = "withdrew";
  EarnPositionActionType2["TRANSFERRED"] = "transferred";
  EarnPositionActionType2["PERMISSIONS_MODIFIED"] = "modified permissions";
  return EarnPositionActionType2;
})(EarnPositionActionType || {});
var EarnPermission = /* @__PURE__ */ ((EarnPermission2) => {
  EarnPermission2["INCREASE"] = "INCREASE";
  EarnPermission2["WITHDRAW"] = "WITHDRAW";
  return EarnPermission2;
})(EarnPermission || {});






exports.EarnPermission = EarnPermission; exports.EarnPositionActionType = EarnPositionActionType; exports.FeeType = FeeType; exports.StrategyRiskLevel = StrategyRiskLevel; exports.StrategyYieldType = StrategyYieldType;
