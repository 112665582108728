"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _material = require('@mui/material');
var _containerbox = require('../container-box');
var _icons = require('../../icons');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
const StyledPaginationArrow = _styledcomponents2.default.call(void 0, _material.IconButton)`
  ${(_ref) => {
  let {
    theme: {
      palette,
      spacing
    },
    disabled
  } = _ref;
  return `
  padding: ${spacing(1 / 4)};
  border-radius: 50%;
  background-color: ${_theme.colors[palette.mode].background.secondary};
  border: 1px solid ${_theme.colors[palette.mode].border.border1};
  ${!disabled && `box-shadow: ${_theme.colors[palette.mode].dropShadow.dropShadow100};`}
  color: ${_theme.colors[palette.mode].accentPrimary};
  transition: all 0.3s;
  :disabled {
    background-color: ${_theme.colors[palette.mode].background.secondary};
    & .MuiSvgIcon-root {
      color: ${_theme.colors[palette.mode].typography.typo4};
    }
  }
`;
}}
`;
const StyledPageButton = _styledcomponents2.default.call(void 0, _material.IconButton)`
  ${(_ref2) => {
  let {
    theme: {
      palette,
      spacing
    },
    $active
  } = _ref2;
  return `
  width: ${spacing(6)};
  height: ${spacing(6)};
  background-color: transparent;
  transition: background-color 0.3s;
  ${$active && `background-color: ${_theme.colors[palette.mode].accentPrimary};
    :hover {
      background-color: ${_theme.colors[palette.mode].accentPrimary};
    }
  `}
`;
}}
`;
const StyledArrowBackIcon = _styledcomponents2.default.call(void 0, _icons.ArrowBackIcon).attrs({
  fontSize: "small"
})`
  transition: all 0.2s;
`;
const StyledArrowForwardIcon = _styledcomponents2.default.call(void 0, _icons.ArrowForwardIcon).attrs({
  fontSize: "small"
})`
  transition: all 0.2s;
`;
function CustomTablePaginationActions(_ref3) {
  let {
    count,
    getItemAriaLabel,
    onPageChange,
    page: pageZeroIndexed,
    rowsPerPage
  } = _ref3;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  const page = pageZeroIndexed + 1;
  const totalPages = Math.ceil(count / rowsPerPage);
  const pagesOptions = _react.useMemo.call(void 0, () => {
    let pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else if (page <= 3) {
      pages = [1, 2, 3, "...", totalPages];
    } else if (page > 3 && page < totalPages - 2) {
      pages = [1, "...", page, "...", totalPages];
    } else {
      pages = [1, "...", totalPages - 2, totalPages - 1, totalPages];
    }
    return pages;
  }, [totalPages, rowsPerPage, page]);
  const onPageChangeHandler = (newPage) => {
    onPageChange(null, newPage);
  };
  return /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    fullWidth: true,
    alignItems: "center",
    justifyContent: "center"
  }, /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 4,
    alignItems: "center"
  }, /* @__PURE__ */ _react2.default.createElement(StyledPaginationArrow, {
    onClick: () => onPageChangeHandler(page - 2),
    disabled: page === 1,
    "aria-label": getItemAriaLabel("previous")
  }, /* @__PURE__ */ _react2.default.createElement(StyledArrowBackIcon, null)), /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 3,
    alignItems: "center"
  }, pagesOptions.map((option, i) => /* @__PURE__ */ _react2.default.createElement(StyledPageButton, {
    key: `${option}-${i}`,
    onClick: () => option !== "..." && onPageChange(null, option - 1),
    $active: option === page,
    "aria-label": option === 1 ? getItemAriaLabel("first") : option === totalPages ? getItemAriaLabel("last") : void 0
  }, /* @__PURE__ */ _react2.default.createElement(_material.Typography, {
    variant: option === page ? "labelLargeSemibold" : "labelLargeRegular",
    color: option === page ? _theme.colors[mode].accent.accent100 : _theme.colors[mode].typography.typo2
  }, option)))), /* @__PURE__ */ _react2.default.createElement(StyledPaginationArrow, {
    onClick: () => onPageChangeHandler(page),
    disabled: page === totalPages || totalPages === 0,
    "aria-label": getItemAriaLabel("next")
  }, /* @__PURE__ */ _react2.default.createElement(StyledArrowForwardIcon, null))));
}
const TablePagination = (props) => /* @__PURE__ */ _react2.default.createElement(_material.TablePagination, _extends({}, props, {
  ActionsComponent: CustomTablePaginationActions
}));


exports.TablePagination = TablePagination;
