"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const formControlLabelVariant = (mode) => ({
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        "& .Mui-disabled": {
          color: _colors.colors[mode].typography.typo3,
          opacity: 0.4
        }
      },
      label: {
        "&.Mui-disabled": {
          color: _colors.colors[mode].typography.typo3
        }
      }
    }
  }
});


exports.formControlLabelVariant = formControlLabelVariant;
