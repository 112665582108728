"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function Money4(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 24",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16.998 21.2539H6.99805C3.34805 21.2539 1.24805 19.1539 1.24805 15.5039V8.50391C1.24805 4.85391 3.34805 2.75391 6.99805 2.75391H16.998C20.648 2.75391 22.748 4.85391 22.748 8.50391V15.5039C22.748 19.1539 20.648 21.2539 16.998 21.2539ZM6.99805 4.25391C4.13805 4.25391 2.74805 5.64391 2.74805 8.50391V15.5039C2.74805 18.3639 4.13805 19.7539 6.99805 19.7539H16.998C19.858 19.7539 21.248 18.3639 21.248 15.5039V8.50391C21.248 5.64391 19.858 4.25391 16.998 4.25391H6.99805Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12 9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.5 10.75C4.09 10.75 3.75 10.41 3.75 10V8.5C3.75 6.71 5.21 5.25 7 5.25H8.5C8.91 5.25 9.25 5.59 9.25 6C9.25 6.41 8.91 6.75 8.5 6.75H7C6.04 6.75 5.25 7.54 5.25 8.5V10C5.25 10.41 4.91 10.75 4.5 10.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M19.498 10.75C19.088 10.75 18.748 10.41 18.748 10V8.5C18.748 7.54 17.958 6.75 16.998 6.75H15.498C15.088 6.75 14.748 6.41 14.748 6C14.748 5.59 15.088 5.25 15.498 5.25H16.998C18.788 5.25 20.248 6.71 20.248 8.5V10C20.248 10.41 19.908 10.75 19.498 10.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.5 18.7539H7C5.21 18.7539 3.75 17.2939 3.75 15.5039V14.0039C3.75 13.5939 4.09 13.2539 4.5 13.2539C4.91 13.2539 5.25 13.5939 5.25 14.0039V15.5039C5.25 16.4639 6.04 17.2539 7 17.2539H8.5C8.91 17.2539 9.25 17.5939 9.25 18.0039C9.25 18.4139 8.91 18.7539 8.5 18.7539Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16.998 18.7539H15.498C15.088 18.7539 14.748 18.4139 14.748 18.0039C14.748 17.5939 15.088 17.2539 15.498 17.2539H16.998C17.958 17.2539 18.748 16.4639 18.748 15.5039V14.0039C18.748 13.5939 19.088 13.2539 19.498 13.2539C19.908 13.2539 20.248 13.5939 20.248 14.0039V15.5039C20.248 17.2939 18.788 18.7539 16.998 18.7539Z"
  }));
}


exports.default = Money4;
