"use strict";Object.defineProperty(exports, "__esModule", {value: true});var TransactionTypes = /* @__PURE__ */ ((TransactionTypes2) => {
  TransactionTypes2["newPosition"] = "NEW_POSITION";
  TransactionTypes2["newPair"] = "NEW_PAIR";
  TransactionTypes2["wrapEther"] = "WRAP_ETHER";
  TransactionTypes2["terminatePosition"] = "TERMINATE_POSITION";
  TransactionTypes2["withdrawPosition"] = "WITHDRAW_POSITION";
  TransactionTypes2["addFundsPosition"] = "ADD_FUNDS_POSITION";
  TransactionTypes2["noOp"] = "NO_OP";
  TransactionTypes2["removeFunds"] = "REMOVE_FUNDS";
  TransactionTypes2["modifySwapsPosition"] = "MODIFY_SWAPS_POSITION";
  TransactionTypes2["modifyRateAndSwapsPosition"] = "MODIFY_RATE_AND_SWAPS_POSITION";
  TransactionTypes2["transferPosition"] = "TRANSFER_POSITION";
  TransactionTypes2["approveCompanion"] = "APPROVE_COMPANION";
  TransactionTypes2["modifyPermissions"] = "MODIFY_PERMISSIONS";
  TransactionTypes2["migratePosition"] = "MIGRATE_POSITION";
  TransactionTypes2["migratePositionYield"] = "MIGRATE_POSITION_YIELD";
  TransactionTypes2["withdrawFunds"] = "WITHDRAW_FUNDS";
  TransactionTypes2["resetPosition"] = "RESET_POSITION";
  TransactionTypes2["eulerClaimTerminateMany"] = "EULER_CLAIM_TERMINATE_MANY";
  TransactionTypes2["eulerClaimPermitMany"] = "EULER_CLAIM_PERMIT_MANY";
  TransactionTypes2["eulerClaimApproveMigrator"] = "EULER_CLAIM_APPROVE_MIGRATOR";
  TransactionTypes2["eulerClaimClaimFromMigrator"] = "EULER_CLAIM_CLAIM_FROM_MIGRATOR";
  TransactionTypes2["approveToken"] = "APPROVE_TOKEN";
  TransactionTypes2["approveTokenExact"] = "APPROVE_TOKEN_EXACT";
  TransactionTypes2["transferToken"] = "TRANSFER_TOKEN";
  TransactionTypes2["swap"] = "SWAP";
  TransactionTypes2["wrap"] = "WRAP";
  TransactionTypes2["unwrap"] = "UNWRAP";
  TransactionTypes2["claimCampaign"] = "CLAIM_CAMPAIGN";
  TransactionTypes2["earnCreate"] = "EARN_CREATE";
  TransactionTypes2["earnIncrease"] = "EARN_INCREASE";
  TransactionTypes2["earnWithdraw"] = "EARN_WITHDRAW";
  return TransactionTypes2;
})(TransactionTypes || {});
const DcaTransactionArrayTypes = [
  "NEW_POSITION" /* newPosition */,
  "TERMINATE_POSITION" /* terminatePosition */,
  "WITHDRAW_POSITION" /* withdrawPosition */,
  "ADD_FUNDS_POSITION" /* addFundsPosition */,
  "REMOVE_FUNDS" /* removeFunds */,
  "MODIFY_SWAPS_POSITION" /* modifySwapsPosition */,
  "MODIFY_RATE_AND_SWAPS_POSITION" /* modifyRateAndSwapsPosition */,
  "TRANSFER_POSITION" /* transferPosition */,
  "APPROVE_COMPANION" /* approveCompanion */,
  "MODIFY_PERMISSIONS" /* modifyPermissions */,
  "MIGRATE_POSITION" /* migratePosition */,
  "MIGRATE_POSITION_YIELD" /* migratePositionYield */,
  "WITHDRAW_FUNDS" /* withdrawFunds */,
  "RESET_POSITION" /* resetPosition */,
  "EULER_CLAIM_TERMINATE_MANY" /* eulerClaimTerminateMany */,
  "EULER_CLAIM_PERMIT_MANY" /* eulerClaimPermitMany */,
  "EULER_CLAIM_APPROVE_MIGRATOR" /* eulerClaimApproveMigrator */,
  "EULER_CLAIM_CLAIM_FROM_MIGRATOR" /* eulerClaimClaimFromMigrator */
];
function isDcaType(transactionType) {
  return DcaTransactionArrayTypes.includes(transactionType.type);
}
const CommonTransactionArrayTypes = [
  "APPROVE_TOKEN" /* approveToken */,
  "APPROVE_TOKEN_EXACT" /* approveTokenExact */,
  "TRANSFER_TOKEN" /* transferToken */
];
function isCommonType(transactionType) {
  return CommonTransactionArrayTypes.includes(transactionType.type);
}
const AggregatorTransactionArrayTypes = [
  "SWAP" /* swap */,
  "WRAP" /* wrap */,
  "UNWRAP" /* unwrap */
];
function isAggregatorType(transactionType) {
  return AggregatorTransactionArrayTypes.includes(transactionType.type);
}
const EarnTransactionArrayTypes = [
  "EARN_CREATE" /* earnCreate */,
  "EARN_INCREASE" /* earnIncrease */,
  "EARN_WITHDRAW" /* earnWithdraw */
];
function isEarnType(transactionType) {
  return EarnTransactionArrayTypes.includes(transactionType.type);
}
const CampaignTransactionArrayTypes = ["CLAIM_CAMPAIGN" /* claimCampaign */];
function isCampaignType(transactionType) {
  return CampaignTransactionArrayTypes.includes(transactionType.type);
}
var TransactionApplicationIdentifier = /* @__PURE__ */ ((TransactionApplicationIdentifier2) => {
  TransactionApplicationIdentifier2["TRANSFER"] = "TRANSFER";
  TransactionApplicationIdentifier2["SWAP"] = "SWAP";
  TransactionApplicationIdentifier2["DCA"] = "DCA";
  TransactionApplicationIdentifier2["EARN_CREATE"] = "EARN_CREATE";
  TransactionApplicationIdentifier2["EARN_INCREASE"] = "EARN_INCREASE";
  TransactionApplicationIdentifier2["EARN_WITHDRAW"] = "EARN_WITHDRAW";
  return TransactionApplicationIdentifier2;
})(TransactionApplicationIdentifier || {});













exports.AggregatorTransactionArrayTypes = AggregatorTransactionArrayTypes; exports.CampaignTransactionArrayTypes = CampaignTransactionArrayTypes; exports.CommonTransactionArrayTypes = CommonTransactionArrayTypes; exports.DcaTransactionArrayTypes = DcaTransactionArrayTypes; exports.EarnTransactionArrayTypes = EarnTransactionArrayTypes; exports.TransactionApplicationIdentifier = TransactionApplicationIdentifier; exports.TransactionTypes = TransactionTypes; exports.isAggregatorType = isAggregatorType; exports.isCampaignType = isCampaignType; exports.isCommonType = isCommonType; exports.isDcaType = isDcaType; exports.isEarnType = isEarnType;
