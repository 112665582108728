"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildPopoverVariant = (mode) => ({
  MuiPopover: {
    defaultProps: {
      slotProps: {
        paper: {
          style: {
            boxShadow: _colors.colors[mode].dropShadow.dropShadow300
          }
        }
      }
    }
  }
});


exports.buildPopoverVariant = buildPopoverVariant;
