"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildTableVariant = (mode) => ({
  MuiTableContainer: {
    styleOverrides: {
      root: {
        backgroundColor: `${_colors.colors[mode].background.quarteryNoAlpha}`,
        "&.noSeparateRows": {
          backgroundColor: `${_colors.colors[mode].background.secondary} !important`,
          ".MuiTableRow-root": {
            backgroundColor: `inherit !important`,
            borderRadius: 0,
            "&:last-of-type .MuiTableCell-root": {
              borderBottom: `none`
            }
          },
          ".MuiTableRow-head": {
            backgroundColor: `${_colors.colors[mode].background.secondary} !important`,
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${_colors.colors[mode].border.border2} !important`
            }
          },
          ".MuiTable-root": {
            borderSpacing: "0px !important"
          },
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${_colors.colors[mode].border.border2}`,
            borderRadius: 0,
            padding: _constants.SPACING.call(void 0, 4)
          }
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: `${_colors.colors[mode].background.secondary} !important`
      },
      hover: {
        "&:hover": {
          backgroundColor: `${_colors.colors[mode].background.tertiary} !important`
        }
      },
      head: {
        backgroundColor: `${_colors.colors[mode].background.quarteryNoAlpha} !important`
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        borderBottom: "none",
        "&:first-of-type": {
          paddingLeft: `${_constants.SPACING.call(void 0, 6)}`
        },
        "&:last-of-type": {
          paddingRight: `${_constants.SPACING.call(void 0, 6)}`
        }
      },
      body: {
        "&:first-of-type": {
          borderTopLeftRadius: _constants.SPACING.call(void 0, 2),
          borderBottomLeftRadius: _constants.SPACING.call(void 0, 2)
        },
        "&:last-of-type": {
          borderTopRightRadius: _constants.SPACING.call(void 0, 2),
          borderBottomRightRadius: _constants.SPACING.call(void 0, 2)
        }
      }
    }
  },
  MuiTablePagination: {
    defaultProps: {
      component: "div",
      rowsPerPageOptions: [],
      labelDisplayedRows: () => ""
    },
    styleOverrides: {
      toolbar: {
        padding: `${_constants.SPACING.call(void 0, 6)} 0 0 0`
      },
      spacer: {
        display: "none"
      },
      displayedRows: {
        display: "none"
      }
    }
  }
});


exports.buildTableVariant = buildTableVariant;
