"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ = require('../');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _reactvirtuoso = require('react-virtuoso');
var _theme = require('../../theme');
var _icons = require('../../icons');
const StyledBodySmallRegularTypo2 = _styledcomponents2.default.call(void 0, _.Typography).attrs((_ref) => {
  let {
    theme: {
      palette: {
        mode
      }
    },
    ...rest
  } = _ref;
  return {
    variant: "bodySmallRegular",
    color: _theme.colors[mode].typography.typo2,
    noWrap: true,
    ...rest
  };
})``;
const StyledBodySmallRegularTypo3 = _styledcomponents2.default.call(void 0, _.Typography).attrs((_ref2) => {
  let {
    theme: {
      palette: {
        mode
      }
    },
    ...rest
  } = _ref2;
  return {
    variant: "bodySmallRegular",
    color: _theme.colors[mode].typography.typo3,
    noWrap: true,
    ...rest
  };
})``;
const StyledBodySmallBoldTypo2 = _styledcomponents2.default.call(void 0, _.Typography).attrs((_ref3) => {
  let {
    theme: {
      palette: {
        mode
      }
    },
    ...rest
  } = _ref3;
  return {
    variant: "bodySmallBold",
    color: _theme.colors[mode].typography.typo3,
    noWrap: true,
    ...rest
  };
})``;
const StyledBodySmallLabelTypography = _styledcomponents2.default.call(void 0, _.Typography).attrs((_ref4) => {
  let {
    theme: {
      palette: {
        mode
      }
    },
    ...rest
  } = _ref4;
  return {
    variant: "bodySmallLabel",
    color: _theme.colors[mode].typography.typo3,
    noWrap: true,
    ...rest
  };
})``;
const StyledRow = _styledcomponents2.default.call(void 0, _.TableRow)`
  ${(_ref5) => {
  let {
    onClick
  } = _ref5;
  return !!onClick ? "cursor: pointer;" : "";
}}
  &:hover {
    ${_icons.AnimatedChevronRightIcon} {
      transition: transform 0.15s ease;
      transform: translateX(${(_ref6) => {
  let {
    theme
  } = _ref6;
  return theme.spacing(1);
}});
    }
  }
`;
function buildVirtuosoTableComponents() {
  return {
    Scroller: /* @__PURE__ */ _react.forwardRef.call(void 0, function TableScroller(props, ref) {
      return /* @__PURE__ */ _react2.default.createElement(_.TableContainer, _extends({}, props, {
        ref
      }));
    }),
    Table: (props) => /* @__PURE__ */ _react2.default.createElement(_.Table, _extends({
      sx: {
        padding: 0
      }
    }, props)),
    TableHead: _.TableHead,
    TableRow: (_ref7) => {
      let {
        item,
        context,
        ...props
      } = _ref7;
      return /* @__PURE__ */ _react2.default.createElement(StyledRow, _extends({
        onClick: _optionalChain([context, 'optionalAccess', _3 => _3.onRowClick]) ? () => _optionalChain([context, 'access', _4 => _4.onRowClick, 'optionalCall', _5 => _5(props["data-index"])]) : void 0
      }, props));
    },
    TableBody: /* @__PURE__ */ _react.forwardRef.call(void 0, function VirtuosoTableBody(props, ref) {
      return /* @__PURE__ */ _react2.default.createElement(_.TableBody, _extends({}, props, {
        ref
      }));
    })
  };
}
function VirtualizedTable(_ref8) {
  let {
    data,
    itemContent,
    context,
    fetchMore,
    header,
    VirtuosoTableComponents,
    separateRows = true,
    height = "100%"
  } = _ref8;
  return /* @__PURE__ */ _react2.default.createElement(_reactvirtuoso.TableVirtuoso, {
    data,
    style: {
      height
    },
    components: VirtuosoTableComponents,
    fixedHeaderContent: header,
    itemContent,
    endReached: fetchMore,
    context,
    className: !separateRows ? "noSeparateRows" : ""
  });
}







exports.StyledBodySmallBoldTypo2 = StyledBodySmallBoldTypo2; exports.StyledBodySmallLabelTypography = StyledBodySmallLabelTypography; exports.StyledBodySmallRegularTypo2 = StyledBodySmallRegularTypo2; exports.StyledBodySmallRegularTypo3 = StyledBodySmallRegularTypo3; exports.VirtualizedTable = VirtualizedTable; exports.buildVirtuosoTableComponents = buildVirtuosoTableComponents;
