"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function MoneyAddIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 21 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.1253 12.9714C8.63366 12.9714 7.41699 11.7547 7.41699 10.263C7.41699 8.77135 8.63366 7.55469 10.1253 7.55469C11.617 7.55469 12.8337 8.77135 12.8337 10.263C12.8337 11.7547 11.617 12.9714 10.1253 12.9714ZM10.1253 8.80469C9.32533 8.80469 8.66699 9.46302 8.66699 10.263C8.66699 11.063 9.32533 11.7214 10.1253 11.7214C10.9253 11.7214 11.5837 11.063 11.5837 10.263C11.5837 9.46302 10.9253 8.80469 10.1253 8.80469Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M15.542 12.9714C15.2003 12.9714 14.917 12.688 14.917 12.3464V8.17969C14.917 7.83802 15.2003 7.55469 15.542 7.55469C15.8837 7.55469 16.167 7.83802 16.167 8.17969V12.3464C16.167 12.688 15.8837 12.9714 15.542 12.9714Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.29134 19.2214C2.90801 19.2214 1.60801 18.488 0.90801 17.2964C0.53301 16.6964 0.333008 15.988 0.333008 15.263C0.333008 13.0797 2.10801 11.3047 4.29134 11.3047C6.47467 11.3047 8.24967 13.0797 8.24967 15.263C8.24967 15.988 8.04967 16.6964 7.67467 17.3047C6.97467 18.488 5.67467 19.2214 4.29134 19.2214ZM4.29134 12.5547C2.79967 12.5547 1.58301 13.7714 1.58301 15.263C1.58301 15.7547 1.71634 16.238 1.97468 16.6547C2.45801 17.4713 3.34967 17.9714 4.29134 17.9714C5.23301 17.9714 6.12467 17.4713 6.60801 16.663C6.86634 16.238 6.99967 15.763 6.99967 15.263C6.99967 13.7714 5.78301 12.5547 4.29134 12.5547Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M5.54146 15.8711H3.0498C2.70814 15.8711 2.4248 15.5878 2.4248 15.2461C2.4248 14.9044 2.70814 14.6211 3.0498 14.6211H5.54146C5.88313 14.6211 6.16646 14.9044 6.16646 15.2461C6.16646 15.5878 5.88313 15.8711 5.54146 15.8711Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.29199 17.1479C3.95033 17.1479 3.66699 16.8646 3.66699 16.5229V14.0312C3.66699 13.6896 3.95033 13.4062 4.29199 13.4062C4.63366 13.4062 4.91699 13.6896 4.91699 14.0312V16.5229C4.91699 16.8729 4.63366 17.1479 4.29199 17.1479Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.292 17.556H7.20866C6.86699 17.556 6.58366 17.2727 6.58366 16.931C6.58366 16.5893 6.86699 16.306 7.20866 16.306H14.292C16.6753 16.306 17.8337 15.1477 17.8337 12.7643V7.76432C17.8337 5.38099 16.6753 4.22266 14.292 4.22266H5.95866C3.57533 4.22266 2.41699 5.38099 2.41699 7.76432V13.0143C2.41699 13.356 2.13366 13.6393 1.79199 13.6393C1.45033 13.6393 1.16699 13.356 1.16699 13.0143V7.76432C1.16699 4.72266 2.91699 2.97266 5.95866 2.97266H14.292C17.3337 2.97266 19.0837 4.72266 19.0837 7.76432V12.7643C19.0837 15.806 17.3337 17.556 14.292 17.556Z"
  }));
}


exports.default = MoneyAddIcon;
